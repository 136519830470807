import Header from "./components/Header"
import Footer from "./components/Footer"
import IconBTN from "./components/IconBTN"
import FeatCard from "./components/FeatCard"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import "./Platform.css"
import { useContext } from "react"
import { LocationContext } from "../App"

const Futures = () => {
    const navigate = useNavigate();
    const {languageBook, urlData} = useContext(LocationContext);

    const toVideo = () => {
        const targetVideo = document.querySelector("#futures-video");
        navigate("/futures-teaching/#futures-video");

        if(targetVideo){
            targetVideo.play();
        }
    }

    return(
        <>
            <Helmet>
                <html lang={languageBook.lang} />
                <title>{`${languageBook.futuresPlatform} | ${languageBook.siteTitle}`}</title>
                <meta name="description" content={languageBook.seoDescFutures} />
                <meta name="keywords" content={languageBook.seoKeywordsFutures}></meta>
                <link ref="canonical" href={`https://unite-information.com${urlData.location}${urlData.page}`} />

                <meta name="og:url" content={`https://unite-information.com${urlData.location}${urlData.page}`} />
                <meta property="og:title" content={`${languageBook.futuresPlatform} | ${languageBook.siteTitle}`} />
                <meta property="og:image" content="/pictures/un077-logo-white-light-square.png" />
                <meta property="og:description" content={languageBook.seoDescFutures} />

                <link rel="alternate" hreflang="zh-TW" href="https://unite-information.com/futures-teaching" />
                <link rel="alternate" hreflang="en-US" href="https://unite-information.com/en-us/futures-teaching" />
                <link rel="alternate" hreflang="ja-JP" href="https://unite-information.com/ja-jp/futures-teaching" />
            </Helmet>
            <Header />
            <main>
                <section id="btns-sec" className="reading-sec">
                    <div id="platform-name">
                        <img src="/pictures/futures-logo.png" alt="futures-logo" />
                        <h1 className="text-gradient">{languageBook.futuresPlatform}</h1>
                    </div>
                    <div className="btns-box">
                        <IconBTN imgSrc={"/pictures/smartphone_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"mobile"} btnText={languageBook.phoneLogin} link={"https://www.ft988.net/mm"} isNewPage={true} />
                        <IconBTN imgSrc={"/pictures/desktop_windows_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"desktop"} btnText={languageBook.webLogin} link={"https://www.ft988.net/web"} isNewPage={true} />
                        <IconBTN imgSrc={"/pictures/terminal_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"software"} btnText={languageBook.programDownload} link={"/sw/聯合.msi"} isNewPage={false} />
                    </div>
                </section>
                <section id="dis-sec" className="reading-sec">
                    <h2 className="text-center desktop-text">{languageBook.futuresEasy00Title}</h2>
                    <button id="go-btn" className="go-btn" onClick={toVideo}>{languageBook.watchVideo}</button>
                    <p>
                        {languageBook.futuresEasy00Desc}
                    </p>
                    <div id="features">
                        <div className="card">
                            <img src="/pictures/stock-1.png" alt="stock-market-1" />
                            <h3>{languageBook.futuresEasy01Title}</h3>
                            <p>{languageBook.futuresEasy01Desc}</p>
                        </div>
                        <div className="card">
                            <img src="/pictures/stock-market-1.png" alt="stock-1" />
                            <h3>{languageBook.futuresEasy02Title}</h3>
                            <p>{languageBook.futuresEasy02Desc}</p>
                        </div>
                        {/* <p>
                            透過公司代墊期貨客戶保證金，海外期貨免換匯手續，一律以TWD新台幣計價，幫助客戶轉單，公司賺取手續費價差，設定每日最高上限盈虧控制客戶風險。
                        </p> */}
                    </div>
                    <div className="video-div">
                        <video id="futures-video" className="show-video" poster="/pictures/futures-video-poster.png" controls loop muted>
                            <source src="https://storage.googleapis.com/un077-official.appspot.com/20240812_unite-information_futures.mp4" type="video/mp4" />
                            您的瀏覽器不支援 HTML5 視訊。
                        </video>
                    </div>
                    
                </section>
                <section id="key-sec" className="reading-sec">
                    <h2>{languageBook.futuresFeat00Title}</h2>
                    <p>
                        {languageBook.futuresFeat00Desc}
                    </p>
                    <div id="video-wrapper">
                        {/* <div id="sticky-video-container">
                            <video controls loop muted>
                                <source src="https://storage.googleapis.com/un077-videos/un077-futures-video-rework_20240722.mp4" type="video/mp4" />
                            </video>
                            
                        </div> */}
                        <div id="key-feat-grid">
                            <FeatCard title={languageBook.futuresFeat01Title} text={languageBook.futuresFeat01Desc} imgSrc={"/pictures/smartphone_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={languageBook.futuresFeat02Title} text={languageBook.futuresFeat02Desc} imgSrc={"/pictures/search_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={languageBook.futuresFeat03Title} text={languageBook.futuresFeat03Desc} imgSrc={"/pictures/arrows_outward_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={languageBook.futuresFeat04Title} text={languageBook.futuresFeat04Desc} imgSrc={"/pictures/motion_photos_paused_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={languageBook.futuresFeat05Title} text={languageBook.futuresFeat05Desc} imgSrc={"/pictures/point_scan_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                        </div>
                    </div>                    
                </section>
            </main>
            <Footer />
        </>
    )
}

export default Futures;